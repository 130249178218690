import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useState} from 'react'
import {AdminProductDTO} from '../../../api/data-contracts'
import _ from 'lodash'
import AdminProductDetailsForm from '../../../components/admin/product/AdminProductDetailsForm'
import AdminProductPricesForm from '../../../components/admin/product/AdminProductPricesForm'

function AdminProductDetailPopup(props: {product?: AdminProductDTO | null; isNew?: boolean}) {
  const [modalShow, setModalShow] = useState(false)

  const handleModalClose = () => setModalShow(false)

  const load = () => {
    setModalShow(true)
  }

  return (
    <>
      {props.isNew ? (
        <div
          className='btn btn-primary btn-sm mb-5'
          onClick={() => {
            load()
          }}
        >
          Ürün Ekle +
        </div>
      ) : (
        <div
          onClick={() => {
            load()
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
        </div>
      )}

      <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
        <Modal.Header closeButton>
          {!props.isNew ? (
            <h5 className='modal-title'>{props.product?.title} </h5>
          ) : (
            <h5 className='modal-title'>{'Yeni Ürün'} </h5>
          )}
        </Modal.Header>
        <Modal.Body>
          <ul className='nav nav-tabs nav-line-tabs nav-stretch fs-4 fw-bold border-0'>
            <li className='nav-item'>
              <a className='nav-link active' data-bs-toggle='tab' href='#kt_tab_pane_1'>
                Ürün Detayları
              </a>
            </li>
            {!props.isNew && (
              <li className='nav-item'>
                <a className='nav-link' data-bs-toggle='tab' href='#kt_tab_pane_2'>
                  Fiyatlar
                </a>
              </li>
            )}
          </ul>

          <div className='tab-content' id='myTabContent'>
            <div className='tab-pane fade show active mt-5' id='kt_tab_pane_1' role='tabpanel'>
              <AdminProductDetailsForm product={props.product} />
            </div>
            {!props.isNew && (
              <div className={'tab-pane fade  mt-5'} id='kt_tab_pane_2' role='tabpanel'>
                <AdminProductPricesForm product={props.product} />
              </div>
            )}
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminProductDetailPopup
