import {useEffect} from 'react'
import {WizardStep} from '../../models/WizardModels'
import BaseWizardPage from '../../BaseWizardPage'
import {AdvisorCompanyAssignmentStep} from './steps/AdvisorCompanyAssignmentStep'
import CompanyInfoStep from './steps/CompanyInfoStep'
import {useDispatch, useSelector} from 'react-redux'
import {getCommonState, setStep} from '../../../../redux/commonSlice'
import {loadTurkeyWizardDataAsync} from '../../../../redux/productSlice'
import {getCompanyState} from '../../../../redux/companySlice'
import CompanyStep from './steps/CompanyStep'
import PersonalInfoStep from './steps/PersonalInfoStep'
import ExtrasStep from './steps/ExtrasStep'

function TurkeyWizardPage() {
  const commonState = useSelector(getCommonState)
  const companyState = useSelector(getCompanyState)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch<any>(loadTurkeyWizardDataAsync())
  }, [])

  useEffect(() => {
    if (!companyState.Id) {
      dispatch(setStep(1))
    }
    if (companyState.data) {
      //dispatch(setStep(companyState.data.company?.wizardStepNumber ?? 2))
      dispatch(setStep(2))
    }
  }, [companyState.Id])

  const steps: WizardStep[] = []

  steps.push(new WizardStep('SIRKET_DURUMU', 'SIRKET_DURUMU_ACIKLAMA', <CompanyStep />))

  steps.push(
    new WizardStep('MALI_MUSAVIRINIZI_SECIN', 'MALI_MUSAVIR', <AdvisorCompanyAssignmentStep />)
  )
  steps.push(new WizardStep('KISISEL_BILGILER', 'KISISEL_BILGILER_DETAY', <PersonalInfoStep />))
  steps.push(new WizardStep('SIRKET_BILGILERI', 'SIRKET_BILGILERI_ACIKLAMA', <CompanyInfoStep />))
  steps.push(new WizardStep('EK_COZUMLER', '', <ExtrasStep />))

  return <BaseWizardPage steps={steps} />
}

export default TurkeyWizardPage
