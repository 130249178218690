import {BooleanApiData} from './../api/data-contracts'
import {FormikValues} from 'formik'
import {CurrencyEnum, PaymentStateEnum} from '../apiClient/enums'
import {FormStatus} from '../types/FormStatus'
import {Column} from 'react-table'
import {ColumnDescription} from 'react-bootstrap-table-next'

export function convertBase64(file: any) {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result)
    }
    fileReader.onerror = (error) => {
      reject(error)
    }
  })
}

export function defterdar_log(message?: any, ...optionalParams: any[]) {
  if (process.env.REACT_APP_ENV?.includes('DEV') || process.env.REACT_APP_ENV?.includes('TEST')) {
    if (optionalParams.length > 0) {
      console.log(message, optionalParams)
    } else {
      console.log(message)
    }
  }
}

export function isUrl(str: string): boolean {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/
  return urlRegex.test(str)
}

export function form_data_logger(props: any) {
  if (process.env.REACT_APP_ENV?.includes('DEV')) {
    //defterdar_log(props)
  }
}


export const TOKEN_COOKIE_NAME: string = 'token'
export const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || 'i18nConfig'

export function getCurrencySymbol(currency: CurrencyEnum) {
  if (currency == CurrencyEnum.Türk_Lirası) {
    return '₺'
  }
  if (currency == CurrencyEnum.Dolar) {
    return '$'
  }
  if (currency == CurrencyEnum.Euro) {
    return '€'
  }
}

export function setFormikStatus(formikValues: FormikValues, result: BooleanApiData) {
  const status: FormStatus = {
    isSuccessful: result.data ?? false,
    message: result.data ? 'Kayıt Başarılı' : result.serverMessage ?? 'Bir hata oluştu',
  }

  formikValues.setStatus(status)
}

export function getPaymentStateColor(state: number) {
  if (state == PaymentStateEnum.Abonelik_Ödeme_Başarılı || state == PaymentStateEnum.Başarılı) {
    return 'badge-success'
  }

  if (state == PaymentStateEnum.Abonelik_Ödeme_Başarısız || state == PaymentStateEnum.Başarısız) {
    return 'badge-danger'
  }

  if (state == PaymentStateEnum.Approve_Başarısız || state == PaymentStateEnum.İade_Edildi) {
    return 'badge-info'
  }

  if (
    state == PaymentStateEnum.Abonelik_Ödeme_İşleniyor ||
    state == PaymentStateEnum.Bekleyen_Abonelik_Ödemesi ||
    state == PaymentStateEnum.Ödeme_Devam_Ediyor ||
    state == PaymentStateEnum.Ödeme_Oluşturuluyor
  ) {
    return 'badge-warning'
  }
}
