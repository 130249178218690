import React from 'react'
import {UserTypeAuthorizationsEnum, UserTypeEnum} from '../../../../apiClient/enums'
import AuthorizedComponent from '../../../common/auth/AuthorizedComponent'
import {SidebarMenuItem} from '../SidebarMenuItem'
import {getUserState} from '../../../../redux/userSlice'
import {useSelector} from 'react-redux'

function AdminSiderBarMenu() {
  const userState = useSelector(getUserState)
  return (
    <>
      {userState.data?.roleId == UserTypeEnum.Mali_Müşavir && (
        <div className='menu-item'>
          <div className='menu-link'>
            <label className='menu-title'>
              Defterdar Kodunuz: {userState?.data?.displayNumber}
            </label>
          </div>
        </div>
      )}
      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Dashboard_Page}>
        <SidebarMenuItem
          to='/admin-dashboard'
          title='Dashboard'
          icon='/media/icons/duotune/graphs/gra001.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Reports_Page}>
        <SidebarMenuItem
          to='/admin-reports'
          title='Raporlar'
          icon='/media/icons/duotune/abstract/abs047.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Companies_Page}>
        <SidebarMenuItem
          to='/admin-companies'
          title='Firmalar'
          icon='/media/icons/duotune/abstract/abs027.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Non_Customer_Users_Page}>
        <SidebarMenuItem
          to='/admin-potentials'
          title='Talepler'
          icon='/media/icons/duotune/abstract/abs049.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Product_Page}>
        <SidebarMenuItem
          to='/admin-products'
          title='Ürünler'
          icon='/media/icons/duotune/abstract/abs047.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Discount_Page}>
        <SidebarMenuItem
          to='/admin-discounts'
          title='İndirimler'
          icon='/media/icons/duotune/arrows/arr004.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel__EInvoice_Page}>
        <SidebarMenuItem
          to='/admin-invoice'
          title='E-Fatura'
          icon='/media/icons/duotune/finance/fin007.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Company_Update_Reference}>
        <SidebarMenuItem
          to='/admin-references'
          title='Referanslar'
          icon='/media/icons/duotune/abstract/abs037.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>

      <AuthorizedComponent auth={UserTypeAuthorizationsEnum.Admin_Panel_Settings_Page}>
        <SidebarMenuItem
          to='/admin-settings'
          title='Sistem Ayarları'
          icon='/media/icons/duotune/abstract/abs031.svg'
          shouldTranslate={false}
        />
      </AuthorizedComponent>
    </>
  )
}

export default AdminSiderBarMenu
