import {FC, Suspense} from 'react'
import {Navigate, Route, Routes} from 'react-router-dom'
import TopBarProgress from 'react-topbar-progress-indicator'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import AdminCompaniesPage from '../pages/admin/Companies/AdminCompaniesPage'
import PaymentInvoicesPage from '../pages/admin/Invoice/PaymentInvoicesPage'
import AdminPotentialsPage from '../pages/admin/PotentialCustomers/AdminPotentialsPage'
import AdminDashboardPage from '../pages/admin/dashboard/AdminDashboardPage'
import AdminProductsPage from '../pages/admin/Products/AdminProductsPage'
import AdminSettingsPage from '../pages/admin/Settings/AdminSettingsPage'
import AdminReportsPage from '../pages/admin/Report/AdminReportsPage'
import AdminReferencesPage from '../pages/admin/References/AdminReferencesPage'
import AdminCompanyDetailPage from '../pages/admin/Companies/AdminCompanyDetailPage'
import AdminDiscountPage from '../pages/admin/Discount/AdminDiscountPage'

const AdminRoutes = () => {
  return (
    <Routes>
      <Route
        path='admin-dashboard'
        element={
          <SuspensedView>
            <AdminDashboardPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-reports'
        element={
          <SuspensedView>
            <AdminReportsPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-companies'
        element={
          <SuspensedView>
            <AdminCompaniesPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-company/:id'
        element={
          <SuspensedView>
            <AdminCompanyDetailPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-potentials'
        element={
          <SuspensedView>
            <AdminPotentialsPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-products'
        element={
          <SuspensedView>
            <AdminProductsPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-discounts'
        element={
          <SuspensedView>
            <AdminDiscountPage />
          </SuspensedView>
        }
      />

      <Route
        path='admin-invoice'
        element={
          <SuspensedView>
            <PaymentInvoicesPage />
          </SuspensedView>
        }
      />
      <Route
        path='admin-settings'
        element={
          <SuspensedView>
            <AdminSettingsPage />
          </SuspensedView>
        }
      />

      <Route
        path='admin-references'
        element={
          <SuspensedView>
            <AdminReferencesPage />
          </SuspensedView>
        }
      />
      <Route path='*' element={<Navigate to='admin-dashboard' />} />
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--kt-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {AdminRoutes}
