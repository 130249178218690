import {FormikValues} from 'formik'
import _ from 'lodash'
import FormMessage from '../../common/messages/FormMessage'
import CustomDropdown, {DropdownOption} from '../../form/dropdown/CustomDropdown'
import FormCheckboxInput from '../../form/FormCheckboxInput'
import FormikForm from '../../form/FormikForm'
import FormInput from '../../form/FormInput'
import {useSelector, useDispatch} from 'react-redux'
import {UpdateProductRequest, BooleanApiData, AdminProductDTO} from '../../../api/data-contracts'
import {Api_Admin_UpsertProduct} from '../../../apiClient/apiClient'
import {getAdminState} from '../../../redux/adminSlice'
import {postRequestAsync} from '../../../redux/commonSlice'
import {FormStatus} from '../../../types/FormStatus'
import * as Yup from 'yup'
import {ProductTypeEnum} from '../../../apiClient/enums'

function AdminProductDetailsForm(props: {product?: AdminProductDTO | null}) {
  const adminState = useSelector(getAdminState)

  const schema = Yup.object({})

  const dispatch = useDispatch()

  const initData: UpdateProductRequest = {
    categoryId: props.product?.categoryId ?? undefined,
    currencyId: props.product?.currencyId,
    description: props.product?.description ?? '',
    descriptionENG: props.product?.descriptionENG ?? '',
    isActive: props.product?.isActive,
    isActiveOnExtras: props.product?.isActiveOnExtras,
    isActiveOnWizard: props.product?.isActiveOnWizard,
    serviceLength: props.product?.serviceLength,
    serviceType: props.product?.serviceType,
    title: props.product?.title ?? '',
    titleEng: props.product?.titleEng ?? '',
    productTypeId: props.product?.typeId,
  }

  const submit = async (values: UpdateProductRequest, actions: FormikValues) => {
    actions.setStatus()
    values.id = props.product?.id
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpsertProduct,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data == true) {
          actions.setStatus(new FormStatus(true, 'Ürün Kaydedildi.'))
        } else {
          actions.setStatus(new FormStatus(false, response.serverMessage))
        }
      })
  }

  return (
    <FormikForm initialValues={initData} onSubmit={submit} validationSchema={schema}>
      {(form_props) => {
        return (
          <>
            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                <FormInput
                  fieldAttributes={{
                    type: 'text',
                    name: 'title',
                    placeholder: 'TITLE',
                  }}
                  istouched={form_props.touched.title ?? false}
                  errorMessage={form_props.errors.title}
                  placeHolderOnTop={true}
                  checkValid={false}
                />
              </div>
              <div className='d-flex flex-column mb-7 col-xl-5'>
                <FormInput
                  fieldAttributes={{
                    type: 'text',
                    name: 'titleEng',
                    placeholder: 'TITLE_ENG',
                  }}
                  istouched={form_props.touched.titleEng ?? false}
                  errorMessage={form_props.errors.titleEng}
                  placeHolderOnTop={true}
                  checkValid={false}
                />
              </div>
            </div>

            <div className='row h-200px'>
              <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                <FormInput
                  fieldAttributes={{
                    type: 'text',
                    name: 'description',
                    placeholder: 'DESCRIPTION',
                    as: 'textarea',
                    row: 10,
                    className: 'h-150px',
                  }}
                  istouched={form_props.touched.description ?? false}
                  errorMessage={form_props.errors.description}
                  placeHolderOnTop={true}
                  checkValid={false}
                />
              </div>
              <div className='d-flex flex-column mb-7 col-xl-5'>
                <FormInput
                  fieldAttributes={{
                    type: 'text',
                    name: 'descriptionENG',
                    placeholder: 'DESCRIPTION_ENG',
                    as: 'textarea',
                    row: 10,
                    className: 'h-150px',
                  }}
                  istouched={form_props.touched.descriptionENG ?? false}
                  errorMessage={form_props.errors.descriptionENG}
                  placeHolderOnTop={true}
                  checkValid={false}
                />
              </div>
            </div>

            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                <CustomDropdown
                  fieldAttributes={{
                    name: 'productTypeId',
                    placeholder: 'URUN_TIPI',
                    onChange: (event: any) => {},
                  }}
                  noSelectionText='URUN_TIPI_SECINIZ'
                  options={
                    adminState.productsPage?.productTypes?.map((m) => {
                      const v: DropdownOption = {
                        label: m.description ?? '',
                        value: m.id ?? 0,
                      }
                      return v
                    }) ?? []
                  }
                  noSelectionValue={0}
                  placeHolderOnTop={true}
                  setFieldValue={form_props.setFieldValue}
                  valueConverter={parseInt}
                />
              </div>
              <div className='d-flex flex-column mb-7 col-xl-5'>
                {form_props.values.productTypeId === ProductTypeEnum.Ek_Çözüm && (
                  <CustomDropdown
                    fieldAttributes={{
                      name: 'categoryId',
                      placeholder: 'KATEGORI',
                      onChange: (event: any) => {},
                    }}
                    noSelectionText='KATEGORI_SECINIZ'
                    options={
                      adminState.productsPage?.productCategories?.map((m) => {
                        const v: DropdownOption = {
                          label: m.description ?? '',
                          value: m.id ?? 0,
                        }
                        return v
                      }) ?? []
                    }
                    noSelectionValue={0}
                    placeHolderOnTop={true}
                    setFieldValue={form_props.setFieldValue}
                    valueConverter={parseInt}
                  />
                )}
              </div>
            </div>

            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-10 offset-xl-1'>
                <div className='row'>
                  <div className='col-xl-4'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'currencyId',
                        placeholder: 'PARA_BIRIMI',
                        onChange: (event: any) => {},
                      }}
                      noSelectionText='PARA_BIRIMI_SECINIZ'
                      options={
                        adminState.productsPage?.currencies?.map((m) => {
                          const v: DropdownOption = {
                            label: m.description ?? '',
                            value: m.id ?? 0,
                          }
                          return v
                        }) ?? []
                      }
                      noSelectionValue={0}
                      placeHolderOnTop={true}
                      setFieldValue={form_props.setFieldValue}
                      valueConverter={parseInt}
                    />
                  </div>
                  <div className='col-xl-4'>
                    <CustomDropdown
                      fieldAttributes={{
                        name: 'serviceType',
                        placeholder: 'SERVIS_PERIYOD',
                        onChange: (event: any) => {},
                      }}
                      noSelectionText='SERVIS_PERIYOD_SECINIZ'
                      options={
                        adminState.productsPage?.servicePeriods?.map((m) => {
                          const v: DropdownOption = {
                            label: m.description ?? '',
                            value: m.id ?? 0,
                          }
                          return v
                        }) ?? []
                      }
                      noSelectionValue={0}
                      placeHolderOnTop={true}
                      setFieldValue={form_props.setFieldValue}
                      valueConverter={parseInt}
                    />
                  </div>
                  <div className='col-xl-4'>
                    <FormInput
                      fieldAttributes={{
                        type: 'text',
                        name: 'serviceLength',
                        placeholder: 'SERVIS_SURESI',
                      }}
                      istouched={form_props.touched.serviceLength ?? false}
                      errorMessage={form_props.errors.serviceLength}
                      placeHolderOnTop={true}
                      checkValid={false}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='d-flex flex-column mb-7 col-xl-10 offset-xl-1'>
                <div className='row'>
                  <div className='col-xl-4'>
                    <FormCheckboxInput
                      description='AKTIF'
                      name='isActive'
                      setFieldValue={form_props.setFieldValue}
                    />
                  </div>
                  {form_props.values.productTypeId === ProductTypeEnum.Ek_Çözüm && (
                    <>
                      <div className='col-xl-4'>
                        <FormCheckboxInput
                          description='AKTIF_WIZARD'
                          name='isActiveOnWizard'
                          setFieldValue={form_props.setFieldValue}
                        />
                      </div>
                      <div className='col-xl-4'>
                        <FormCheckboxInput
                          description='AKTIF_EKSTRALAR'
                          name='isActiveOnExtras'
                          setFieldValue={form_props.setFieldValue}
                        />
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-xl-10 offset-xl-1'>
                <FormMessage {...form_props.status} />
              </div>
            </div>

            <div className='row'>
              <div className='text-center'>
                <button type='submit' className='btn btn-lg btn-primary me-3 mt-5'>
                  Kaydet
                </button>
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default AdminProductDetailsForm
