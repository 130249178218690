import {useDispatch, useSelector} from 'react-redux'
import {
  BooleanApiData,
  GetPotentialCustomersRequest,
  PotentialCustomerDTO,
  PotentialCustomerDTOIEnumerableApiData,
  UpdateUserStateRequest,
} from '../../../api/data-contracts'
import {getAdminState, setPotentialCustomers} from '../../../redux/adminSlice'
import _ from 'lodash'
import moment from 'moment'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import AreYouSurePopup from '../../../components/common/popup/AreYouSurePopup'
import {
  Api_Admin_ChangeUserState,
  Api_Admin_GetPotentialCustomers,
} from '../../../apiClient/apiClient'
import {setIsLoading, postRequestAsync, closeAreYouSureModal} from '../../../redux/commonSlice'
import clsx from 'clsx'
import {useEffect} from 'react'
import Select from 'react-select/dist/declarations/src/Select'
import DateRangeSelectorHorizontal from '../../../components/form/dropdown/DateRangeSelectorHorizontal'
import FormikForm from '../../../components/form/FormikForm'
import FormInput from '../../../components/form/FormInput'

export interface AdminCompaniesFilter {
  textFilter: string
  companyType: number
}

function AdminPotentialsPage() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  const submit = async (values: GetPotentialCustomersRequest) => {
    dispatch(setIsLoading(true))
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetPotentialCustomers,
        body: values,
      })
    )
      .unwrap()
      .then(async function (response: PotentialCustomerDTOIEnumerableApiData) {
        dispatch<any>(setPotentialCustomers(response.data))
        //setFilteredCompanies(response.data ?? [])
        dispatch(setIsLoading(false))
      })
  }

  useEffect(() => {
    submit(initFilterValues)
  }, [])

  const initFilterValues: GetPotentialCustomersRequest = {
    textSearch: '',
    creationDateEnd: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    creationDateStart: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
  }

  const changeUserActive = async (userId: string, state: boolean) => {
    dispatch(setIsLoading(true))
    const body: UpdateUserStateRequest = {
      userId: userId,
      isActive: state,
    }
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_ChangeUserState,
        body: body,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        dispatch(closeAreYouSureModal())
        dispatch(setIsLoading(false))
        window.location.reload()
      })
  }

  const columns: ColumnDescription[] = [
    {
      dataField: 'firstName',
      text: 'Kişi',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {m.firstName} {m.lastName}
          </div>
        </>
      ),
    },
    {
      dataField: 'mailAddress',
      text: 'E-mail',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.mailAddress}</div>
        </>
      ),
    },
    {
      dataField: 'phoneNumber',
      text: 'Telefon',
      sort: true,
      headerClasses: 'mw-500px',
      classes: 'mw-500px',
      width: 500,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.phoneNumber}</div>
        </>
      ),
    },
    {
      dataField: 'createdAt',
      text: 'Kayıt Zamanı',
      sort: true,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>
            {moment(m.createdAt).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'createdAt',
      text: 'İşlem',
      sort: true,
      headerStyle: {width: '15%'},
      formatter: (cell: any, m: PotentialCustomerDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className=''>
            <AreYouSurePopup
              modalOpener={
                <a className='btn btn-lg btn-warning me-3'>
                  <span className='indicator-label'>
                    {m.isActive ? 'Kullanıcı Pasife Al' : 'Kullanıcı Aktif Et'}
                  </span>
                </a>
              }
              message={'EMIN_MISINIZ'}
              onClick={() => changeUserActive(m.guid ?? '', m.isActive ? false : true)}
            />
          </div>
        </>
      ),
    },
  ]

  const rowClasses = (row: PotentialCustomerDTO, rowIndex: number): string => {
    return clsx({
      'bg-danger bg-opacity-15': !row.isActive,
    })
  }

  return (
    <>
      <div className={`card card-stretch`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5 d-flex justify-content-center'>
          <div className='w-100'>
            <FormikForm initialValues={initFilterValues} onSubmit={submit}>
              {(props) => {
                return (
                  <>
                    <div className='row'>
                      <div className='d-flex flex-column mb-3 fv-row col-xl-6'>
                        <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                          <span className=''>Kayıt Tarihi Aralığı</span>
                        </label>
                        <DateRangeSelectorHorizontal
                          setFieldValue={props.setFieldValue}
                          finishKey='creationDateEnd'
                          startKey='creationDateStart'
                        />
                      </div>

                      <div className='d-flex flex-column mb-3 fv-row col-xl-2'>
                        <FormInput
                          istouched={props.touched.textSearch ?? false}
                          errorMessage={props.errors.textSearch}
                          isTransparent={true}
                          placeHolderOnTop={true}
                          checkValid={false}
                          fieldAttributes={{
                            type: 'text',
                            name: 'textSearch',
                            autoComplete: 'on',
                            placeholder: 'Arama',
                          }}
                        />
                      </div>

                      <div className='d-flex mb-3 fv-row col-xl-2 align-items-end'>
                        <a
                          className='btn btn-lg btn-primary me-3 col-12'
                          onClick={() => {
                            props.resetForm({values: initFilterValues})
                          }}
                        >
                          <span className='indicator-label'>Temizle</span>
                        </a>
                      </div>

                      <div className='d-flex  mb-3 fv-row col-xl-2 align-items-end'>
                        <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                          <span className='indicator-label'>Filtrele</span>
                        </button>
                      </div>
                    </div>
                  </>
                )
              }}
            </FormikForm>
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}

        <div className='card-body py-3'>
          <div className='table-responsive'>
            {(adminState.potentialCustomers?.length ?? 0) > 0 && (
              <>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: adminState?.potentialCustomers?.length,
                    sizePerPage: 15,
                    paginationSize: 15,
                    paginationTotalRenderer: (from, to, size) => (
                      <span className='react-bootstrap-table-pagination-total ms-5'>
                        Toplam {size} talepten {from} - {to} arası gösteriliyor
                      </span>
                    ),
                  })}
                >
                  {({paginationProps, paginationTableProps}) => (
                    <>
                      <div>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                        <BootstrapTable
                          {...paginationTableProps}
                          keyField='index'
                          rowClasses={rowClasses}
                          data={
                            _.orderBy(adminState.potentialCustomers, (o) => o.createdAt, 'desc') ??
                            []
                          }
                          columns={columns}
                          classes='table table-hover table-row-dashed table-rounded mt-5'
                        />
                      </div>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </div>

          {/* begin::Table container */}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AdminPotentialsPage
