import {FieldArray, FormikValues} from 'formik'
import _ from 'lodash'
import FormMessage from '../../common/messages/FormMessage'
import FormikForm from '../../form/FormikForm'
import FormInput from '../../form/FormInput'
import {useSelector, useDispatch} from 'react-redux'
import {
  BooleanApiData,
  AdminProductDTO,
  UpdateProductPriceRequest,
  AdminProductPrice,
} from '../../../api/data-contracts'
import {Api_Admin_UpsertProductPrices} from '../../../apiClient/apiClient'
import {getAdminState} from '../../../redux/adminSlice'
import {postRequestAsync} from '../../../redux/commonSlice'
import {FormStatus} from '../../../types/FormStatus'
import * as Yup from 'yup'
import {defterdar_log} from '../../../utils/utilities'
import CustomDropdown, {DropdownOption} from '../../form/dropdown/CustomDropdown'
import {useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'

function AdminProductPricesForm(props: {product?: AdminProductDTO | null}) {
  const adminState = useSelector(getAdminState)

  const [selectedServiceProviderId, setSelectedServiceProviderId] = useState(0)

  const schema = Yup.object({})

  const dispatch = useDispatch()

  const initData: UpdateProductPriceRequest = {
    prices: props.product?.prices,
  }

  const submit = async (values: UpdateProductPriceRequest, actions: FormikValues) => {
    actions.setStatus()
    values.id = props.product?.id
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpsertProductPrices,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data === true) {
          actions.setStatus(new FormStatus(true, 'Ürün Kaydedildi.'))
        } else {
          actions.setStatus(new FormStatus(false, response.serverMessage))
        }
      })
  }

  function generatePriceForServiceProvider(serviceProviderId: number): AdminProductPrice {
    const financialAdvisorUserId =
      adminState.productsPage?.serviceProviders?.find((m) => m.id === serviceProviderId)?.userId ??
      0

    const name = adminState.productsPage?.serviceProviders?.find(
      (m) => m.id === serviceProviderId
    )?.description

    return {
      financialAdvisorUserId: financialAdvisorUserId,
      financialAdvisorUser: name,
      serviceProviderShares: [
        {
          serviceProviderId: 6,
          serviceProvider: 'Defterdar',
          serviceProviderRole: '',
          serviceProviderRoleId: 0,
          totalPrice: 0,
          totalPriceWithoutTax: 0,
        },
        {
          serviceProviderId: serviceProviderId,
          serviceProvider: name,
          serviceProviderRole: '',
          serviceProviderRoleId: 0,
          totalPrice: 0,
          totalPriceWithoutTax: 0,
        },
      ],
    }
  }

  return (
    <FormikForm
      initialValues={initData}
      onSubmit={submit}
      validationSchema={schema}
      enableReinitialize={true}
    >
      {(form_props) => {
        return (
          <>
            <div className='row'>
              <div className='d-flex flex-column mb-7 fv-row col-xl-8 offset-2'>
                <FieldArray
                  name='prices'
                  render={(arrayHelpers) => (
                    <div className='d-flex flex-column justify-content-center'>
                      <div className='row'>
                        {form_props.values?.prices?.map((price, index) => (
                          <div className='col-xl-12 px-2 pb-5' key={index}>
                            <div className='card card-dashed p-6'>
                              <div className='row mb-2'>
                                <div className='col-xl-6 fw-bold fs-6 text-left'></div>
                                <div className='col-xl-3 fw-bold fs-6 text-left'></div>
                                <div className='col-xl-3 fw-bold fs-6 text-end'>
                                  {(price.financialAdvisorUserId ?? 0) > 0 && (
                                    <div className='card-toolbar'>
                                      <button
                                        type='button'
                                        className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                        data-kt-menu-trigger='click'
                                        data-kt-menu-placement='bottom-end'
                                        data-kt-menu-flip='top-end'
                                        onClick={() => arrayHelpers.remove(index)}
                                      >
                                        <KTSVG
                                          path='/media/icons/duotune/abstract/abs012.svg'
                                          className='svg-icon-muted svg-icon-2hx'
                                        />
                                      </button>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-2'>
                                <div className='col-xl-6 fw-bold fs-6 text-left'>
                                  {price.financialAdvisorUserId
                                    ? price.financialAdvisorUser + ' İçin Fiyatlar'
                                    : 'Varsayılan Fiyatlar'}
                                </div>
                                <div className='col-xl-3 fw-bold fs-6 text-left'>KDV'siz</div>
                                <div className='col-xl-3 fw-bold fs-6 text-left'>KDV'li</div>
                              </div>
                              {price?.serviceProviderShares?.map((m, i) => {
                                return (
                                  <div className='row mb-2' key={i}>
                                    <div className='col-xl-6 fs-6 text-left pb-3'>
                                      <div>
                                        {(m.serviceProviderRoleId ?? 0) > 0 ? (
                                          <div>{m.serviceProviderRole}</div>
                                        ) : (
                                          <>{m.serviceProvider} </>
                                        )}
                                      </div>
                                    </div>
                                    <div className='col-xl-3'>
                                      <FormInput
                                        fieldAttributes={{
                                          type: 'number',
                                          name: `prices.${index}.serviceProviderShares.${i}.totalPriceWithoutTax`,
                                          placeholder: '',
                                          onChange: (event: any) => {
                                            const value = parseFloat(
                                              event.target.value == '' ? 0 : event.target.value
                                            )
                                            form_props.setFieldValue(
                                              `prices.${index}.serviceProviderShares.${i}.totalPriceWithoutTax`,
                                              value
                                            )

                                            form_props.setFieldValue(
                                              `prices.${index}.serviceProviderShares.${i}.totalPrice`,
                                              value * 1.2
                                            )
                                          },
                                        }}
                                        istouched={true}
                                        errorMessage={undefined}
                                        placeHolderOnTop={false}
                                        checkValid={false}
                                      />
                                    </div>
                                    <div className='col-xl-3'>
                                      <FormInput
                                        fieldAttributes={{
                                          type: 'number',
                                          name: `prices.${index}.serviceProviderShares.${i}.totalPrice`,
                                          placeholder: '',
                                          onChange: (event: any) => {
                                            const value = parseFloat(
                                              event.target.value == '' ? 0 : event.target.value
                                            )
                                            form_props.setFieldValue(
                                              `prices.${index}.serviceProviderShares.${i}.totalPrice`,
                                              value
                                            )

                                            // form_props.setFieldValue(
                                            //   `prices.${index}.shares.${i}.totalPriceWithoutTax`,
                                            //   value / 1.2
                                            // )
                                          },
                                        }}
                                        istouched={true}
                                        errorMessage={undefined}
                                        placeHolderOnTop={false}
                                        checkValid={false}
                                      />
                                    </div>
                                  </div>
                                )
                              })}

                              <div className='row'>
                                <div className='col-xl-6 fs-6 text-left'>
                                  {!price.financialAdvisorUserId ? (
                                    <div
                                      className='btn btn-primary btn-sm'
                                      onClick={() => {
                                        const defaultShares = form_props.values?.prices?.find(
                                          (m) => (m.financialAdvisorUserId ?? 0) === 0
                                        )?.serviceProviderShares

                                        form_props.values.prices?.forEach((m, i) => {
                                          if ((m.financialAdvisorUserId ?? 0) > 0) {
                                            price.serviceProviderShares?.forEach((s, j) => {
                                              defterdar_log(
                                                defaultShares?.at(j)?.totalPriceWithoutTax
                                              )

                                              form_props.setFieldValue(
                                                `prices.${i}.serviceProviderShares.${j}.totalPriceWithoutTax`,
                                                defaultShares?.at(j)?.totalPriceWithoutTax
                                              )
                                              form_props.setFieldValue(
                                                `prices.${i}.serviceProviderShares.${j}.totalPrice`,
                                                defaultShares?.at(j)?.totalPrice
                                              )
                                            })
                                          }
                                        })
                                      }}
                                    >
                                      Tüm Mali Müşavirlere Aktar
                                    </div>
                                  ) : (
                                    <div>Toplam</div>
                                  )}
                                </div>
                                <div className='col-xl-3 fs-6 text-left py-3'>
                                  <div>
                                    {_.sumBy(
                                      form_props.values.prices?.at(index)?.serviceProviderShares,
                                      (s) => s.totalPriceWithoutTax ?? 0
                                    ).toFixed(2)}
                                  </div>
                                </div>
                                <div className='col-xl-3 fs-6 text-left py-3'>
                                  {_.sumBy(
                                    form_props.values.prices?.at(index)?.serviceProviderShares,
                                    (s) => s.totalPrice ?? 0
                                  ).toFixed(2)}
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </div>

                      <div className='row'>
                        <div className='col-xl-12'>
                          <div className='row'>
                            <div className='col-xl-4 offset-2'>
                              <CustomDropdown
                                fieldAttributes={{
                                  name: `temp_service_provider_id`,
                                  placeholder: 'SERVIS_SAGLAYICI_SECINIZ',
                                  onChange: (event: any) => {
                                    setSelectedServiceProviderId(parseInt(event.target.value))
                                  },
                                }}
                                noSelectionText='SERVIS_SAGLAYICI_SECINIZ'
                                options={
                                  adminState.productsPage?.serviceProviders?.map((m) => {
                                    const v: DropdownOption = {
                                      label: m.description ?? '',
                                      value: m.id ?? 0,
                                    }
                                    return v
                                  }) ?? []
                                }
                                noSelectionValue={0}
                                placeHolderOnTop={false}
                                setFieldValue={form_props.setFieldValue}
                                valueConverter={parseInt}
                              />
                            </div>
                            <div className='col-xl-4'>
                              <div
                                onClick={() => {
                                  const newPrice =
                                    generatePriceForServiceProvider(selectedServiceProviderId)
                                  defterdar_log(newPrice)
                                  arrayHelpers.push(newPrice)
                                }}
                                className='btn btn-lg btn-primary me-3'
                              >
                                Özel Fiyat Ekle +
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>

            <div className='row mt-5'>
              <div className='col-xl-10 offset-xl-1'>
                <FormMessage {...form_props.status} />
              </div>
            </div>

            <div className='row'>
              <div className='text-center'>
                <button type='submit' className='btn btn-lg btn-primary me-3 mt-5'>
                  Kaydet
                </button>
              </div>
            </div>
          </>
        )
      }}
    </FormikForm>
  )
}

export default AdminProductPricesForm
