import React from 'react'
import TurkeyWizardPage from './customWizards/turkey/TurkeyWizardPage'
import ClemtaWizardPage from './customWizards/clemta/ClemtaWizardPage'
import {useSelector} from 'react-redux'
import {getUserState} from '../../redux/userSlice'
import {CountryEnum} from '../../apiClient/enums'

function WizardPage() {
  const userState = useSelector(getUserState)

  return (
    <>
      {/* {userState.data?.countryId == CountryEnum.Türkiye && <TurkeyWizardPage />}
      {userState.data?.countryId == CountryEnum.Amerika_Birleşik_Devletleri && <ClemtaWizardPage />} */}
      <TurkeyWizardPage />
    </>
  )
}

export default WizardPage
