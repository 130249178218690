export enum CompanyTypeEnum {
  Anonim_Şirketi = 3,
  C_Corporation__General_Corporation_ = 5,
  Limited_Şirketi = 2,
  LLC__Limited_Liability_Company_ = 6,
  Serbest_Meslek = 4,
  Şahıs_Şirketi = 1,
}

export enum ApplicationStepEnum {
  Turkey_AdvisorSelection = 19,
  Turkey_CompanyCreated = 16,
  Turkey_CompanyExtraInfo = 7,
  Turkey_DocumentAreApproved = 12,
  Turkey_DocumentInReview = 11,
  Turkey_DocumentsNeedUpdate = 14,
  Turkey_EconversionStarted = 15,
  Turkey_Finished = 13,
  Turkey_Payment = 21,
  Turkey_PersonalInfo = 20,
  Turkey_WizardFinished = 10,
}

export enum AddressTypeEnum {
  Bireysel_Adres = 1,
  Faaliyet_Adresi = 4,
  Fatura_Adresi = 2,
  Kurumsal_Adres = 3,
}

export enum DocumentTypeEnum {
  Araç_Sıfırsa_Faturası,
  _İkinci_El_ise_Satış_Noter_Belgesi_veya_Kiralama_Belgesi = 18,
}

export enum FileApprovalStatusEnum {
  Onay_Bekliyor = 1,
  Onay_Gerekmiyor = 4,
  Onaylandı = 2,
  Reddedildi = 3,
}

export enum CurrencyEnum {
  Dolar = 2,
  Euro = 3,
  Singapur_Doları = 5,
  Sterlin = 4,
  Türk_Lirası = 1,
}

export enum ProductTypeEnum {
  Cezai_Bedel = 12,
  Clemta_Package = 11,
  Ek_Çözüm = 4,
  İndirim = 6,
  Paket = 3,
  Paket_Alt_Ürünü = 5,
  Silinmiş = 13,
}

export enum ServicePeriodEnum {
  Aylık = 4,
  Günlük = 2,
  Haftalık = 3,
  Saatlik = 1,
  Tek_Sefer = 6,
  Yıllık = 5,
}

export enum UserTypeEnum {
  Admin = 5,
  E_Dönüşüm = 3,
  Mali_Müşavir = 2,
  Mali_Müşavir_Davetli = 4,
  Müşteri = 1,
  Satışçı = 6,
}

export enum AdvisorAssignTypeEnum {
  Auto = 1,
  Invited = 3,
  Pass = 4,
  SelectedWithCode = 2,
}

export enum PaymentStateEnum {
  Abonelik_Ödeme_Başarılı = 8,
  Abonelik_Ödeme_Başarısız = 7,
  Abonelik_Ödeme_İşleniyor = 6,
  Approve_Başarısız = 4,
  Başarılı = 2,
  Başarısız = 3,
  Bekleyen_Abonelik_Ödemesi = 5,
  İade_Edildi = 11,
  İptal = 10,
  Ödeme_Devam_Ediyor = 1,
  Ödeme_Oluşturuluyor = 9,
}

export enum CountryEnum {
  Afganistan = 2,
  Alaska = 3,
  Almanya = 4,
  Amerika_Birleşik_Devletleri = 5,
  Amerikan_Samoa = 6,
  Andora = 7,
  Angola = 8,
  Anguilla = 9,
  Antartika = 10,
  Antigua_ve_Barbuda = 11,
  Arjantin = 12,
  Arnavutluk = 13,
  Aruba = 14,
  Avustralya = 15,
  Avusturya = 16,
  Azerbeycan = 17,
  Bahama_Adaları = 18,
  Bahreyn = 19,
  Bangladeş = 20,
  Barbados = 21,
  Belçika = 23,
  Belize = 22,
  Benin = 24,
  Bermuda = 25,
  Beyaz_Rusya = 26,
  Bhutan = 27,
  Birleşik_Arap_Emirlikleri = 28,
  Bolivya = 29,
  Bosna_Hersek = 30,
  Botswana = 31,
  Bouvet_Adası = 32,
  Brezilya = 33,
  British_Virgin_Islands = 34,
  Bulgaristan = 35,
  Burkina_Faso = 36,
  Burma = 37,
  Burundi = 38,
  Burunei_Sultanlığı = 39,
  Cebelitarık = 40,
  Cezayir = 41,
  Christmas_Adaları = 42,
  Cibuti = 43,
  Cocos_Adaları = 44,
  Çad = 223,
  Çek_Cumhuriyeti = 224,
  Çin = 225,
  Danimarka = 45,
  Demokratik_Kongo_Cumhuriyeti = 46,
  Dominik_Cumhuriyeti = 47,
  Dominika = 48,
  Ekvator = 49,
  Ekvator_Ginesi = 50,
  El_Salvador = 51,
  Endonezya = 52,
  Eritre = 53,
  Ermenistan = 54,
  Estonya = 55,
  Etiyopya = 56,
  Falkland_Adası = 57,
  Faroa_Adaları = 58,
  Fas = 59,
  Fiji = 60,
  Fildişi_Sahili = 61,
  Filipinler = 62,
  Filistin = 63,
  Finlandiya = 64,
  Fransa = 65,
  Fransız_Guyanası = 66,
  Fransız_Polinezyası = 67,
  Gabon = 68,
  Gambiya = 69,
  Gana = 70,
  Gine = 71,
  Gine_Bissau = 72,
  Grenada = 73,
  Grönland = 74,
  Guadalup = 75,
  Guam = 76,
  Guatemala = 77,
  Guernsey = 78,
  Guyana = 79,
  Güney_Afrika_Cumhuriyeti = 80,
  Güney_Kore = 81,
  Gürcistan = 82,
  Haiti = 83,
  Heard_ve_McDonald_Adaları = 84,
  Hırvatistan = 90,
  Hindistan = 85,
  Hollanda = 86,
  Hollanda_Antilleri = 87,
  Honduras = 88,
  Hong_Kong = 89,
  Irak = 91,
  Isle_of_Man = 92,
  İngiliz_Virjin_Adaları = 228,
  İngiltere = 229,
  İran = 230,
  İrlanda = 231,
  İspanya = 232,
  İsrail = 233,
  İsveç = 234,
  İsviçre = 235,
  İtalya = 236,
  İzlanda = 237,
  Jamaika = 93,
  Japonya = 94,
  Jersey = 95,
  Kamboçya = 96,
  Kamerun = 97,
  Kanada = 98,
  Kape_Verde__Yeşil_Burun_ = 99,
  Karadağ = 100,
  Katar = 101,
  Kayman_Adaları = 102,
  Kazakistan = 103,
  Kenya = 104,
  Kırgızistan = 115,
  Kiribati = 105,
  Kolombiya = 106,
  Komor_Adaları = 107,
  Kongo = 108,
  Kosta_Rika = 109,
  Kuveyt = 110,
  Kuzey_Kıbrıs_Türk_C_ = 112,
  Kuzey_Kore = 111,
  Kuzey_Mariana_Adası = 113,
  Küba = 114,
  Laos = 116,
  Lesoto = 117,
  Letonya = 118,
  Liberya = 119,
  Libya = 120,
  Lichtenstein = 121,
  Litvanya = 122,
  Lübnan = 123,
  Lüksemburg = 124,
  Macaristan = 125,
  Madagaskar = 126,
  Makao = 127,
  Makedonya = 128,
  Malavi = 129,
  Maldivler = 130,
  Malezya = 131,
  Mali = 132,
  Malta = 133,
  Marshall_Adası = 134,
  Martinik = 135,
  Mauritus = 136,
  Mayotte = 137,
  Meksika = 138,
  Mısır = 146,
  Mikronezya = 139,
  Moğolistan = 145,
  Moldovya = 140,
  Monaco = 141,
  Montserrat = 142,
  Moritanya = 143,
  Mozambik = 144,
  Namibya = 147,
  Nauru = 148,
  Nepal = 149,
  Nijer = 150,
  Nijerya = 151,
  Nikaragua = 152,
  Niue = 153,
  Norfolk_Adası = 154,
  Norveç = 155,
  Orta_Afrika_Cumhuriyeti = 156,
  Özbekistan = 226,
  Pakistan = 157,
  Palau = 158,
  Panama = 159,
  Papua_Yeni_Gine = 160,
  Paraguay = 161,
  Peru = 162,
  Pitcairn_Adaları = 163,
  Polonya = 164,
  Portekiz = 165,
  Porto_Rico = 166,
  Reunion = 167,
  Romanya = 168,
  Ruanda = 169,
  Rusya = 170,
  Saint_Helena = 171,
  Saint_Kitts_ve_Nevis = 172,
  Saint_Lucia = 173,
  Saint_Pierre_ve_Miquelon = 174,
  Saint_Vincent_ve_Grenadinler = 175,
  Samoa = 176,
  San_Marino = 177,
  Sao_Tome_ve_Principe = 178,
  Senegal = 179,
  Sırbistan = 193,
  Sierra_Leone = 180,
  Singapur = 181,
  Slovakya_Cumhuriyeti = 182,
  Slovenya = 183,
  Solomon_Adaları = 184,
  Somali = 185,
  Sri_Lanka = 186,
  Sudan = 187,
  Surinam = 188,
  Suriye = 189,
  Suudi_Arabistan = 190,
  Svalbard = 191,
  Swaziland = 192,
  Şeysel_Adaları = 238,
  Şili = 239,
  Tacikistan = 194,
  Tanzanya = 195,
  Tayland = 196,
  Tayvan = 197,
  Togo = 198,
  Tokelau = 199,
  Tonga = 200,
  Trinidad_ve_Tobago = 201,
  Tunus = 202,
  Turks_ve_Caicos_Adası = 203,
  Tuvalu = 204,
  Türkiye = 1,
  Türkmenistan = 205,
  Ukrayna = 206,
  Umman = 207,
  Uruguay = 208,
  Uyduristan = 209,
  Ürdün = 227,
  Vallis_ve_Futuna_Adası = 210,
  Vanuatu = 211,
  Vatikan = 212,
  Venezuella = 213,
  Vietnam = 214,
  Virgin_Adaları = 215,
  Yemen = 216,
  Yeni_Kaledonya = 217,
  Yeni_Zelanda = 218,
  Yugoslavya = 219,
  Yunanistan = 220,
  Zambiya = 221,
  Zimbabve = 222,
}

export enum UserTypeAuthorizationsEnum {
  Admin_Panel__EInvoice_Page = 9,
  Admin_Panel_Companies_Page = 7,
  Admin_Panel_Company_Cancel_Payment = 28,
  Admin_Panel_Company_Documents = 29,
  Admin_Panel_Company_Invoice_Download = 31,
  Admin_Panel_Company_Payment_Error = 33,
  Admin_Panel_Company_Payment_Refund = 27,
  Admin_Panel_Company_Suspension = 30,
  Admin_Panel_Company_Update = 11,
  Admin_Panel_Company_Update_Active_Passive = 18,
  Admin_Panel_Company_Update_Email = 22,
  Admin_Panel_Company_Update_Financial_Advisor = 26,
  Admin_Panel_Company_Update_Inspection = 19,
  Admin_Panel_Company_Update_Name = 16,
  Admin_Panel_Company_Update_Phone = 23,
  Admin_Panel_Company_Update_Reference = 24,
  Admin_Panel_Company_Update_State = 20,
  Admin_Panel_Company_Update_Suspension_Reason = 21,
  Admin_Panel_Company_Update_Tax_Number = 17,
  Admin_Panel_Company_User_Active_Passive = 25,
  Admin_Panel_Dashboard_Page = 5,
  Admin_Panel_Discount_Page = 35,
  Admin_Panel_Login = 1,
  Admin_Panel_Non_Customer_Users_Page = 8,
  Admin_Panel_Product_Page = 34,
  Admin_Panel_Report_Commission = 15,
  Admin_Panel_Report_Payment = 32,
  Admin_Panel_Report_Sale = 14,
  Admin_Panel_Reports_Page = 6,
  Admin_Panel_Settings_Page = 10,
  Authorization = 12,
  Login = 13,
}

export enum SmsTemplateEnum {
  Company_Created = 8,
  Docuemnt_Review = 6,
  EConversion_Started = 7,
  First_Payment = 5,
  Register_Phone_Validation = 3,
  Register_Welcome = 4,
}

export enum PaymentSuccessCommandEnum {
  Register_Complete = 1,
  Suspend_Company_For_Advisor_Change = 3,
  Suspend_Company_For_Liquidation = 2,
}

export enum CompanySuspensionReasonEnum {
  Borçlu_Fesih = 1,
  Diğer = 5,
  Mali_Müşavir_Sorunları = 4,
  Paket_Fiyatları_Pahalı = 3,
  Tasfiye_Edildi = 2,
}

export enum CompanyEventEnum {
  COMPANY_BOUGHT_PACKAGE = 6,
  COMPANY_CREATED = 3,
  COMPANY_CREATION_STARTED = 2,
  COMPANY_ECONVERSION_FINISHED = 5,
  COMPANY_ECONVERSION_STARTED = 4,
  COMPANY_REGISTERED = 1,
}

export enum CompanySuspensionStatusEnum {
  Borçlu_Fesih = 8,
  Mali_Müşavir_Değişim_Başladı = 7,
  Mali_Müşavir_Değiştirdi = 4,
  Mali_Müşavir_Değiştirmek_İstiyor = 3,
  Şirket_Kapandı___Tasfiye_Sonuçlandı = 2,
  Şirket_Kapanış_İptal_Edildi = 5,
  Şirketi_Kapatmak_İstiyor___Tasfiye_Başladı = 1,
  Tasfiye_Başladı = 6,
}
