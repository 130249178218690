import {Modal} from 'react-bootstrap'
import {KTSVG} from '../../../../_metronic/helpers'
import {useEffect, useState} from 'react'
import {
  AdminDiscountPageDataApiData,
  AdminProductPrice,
  BooleanApiData,
  DiscountDTO,
  UpsertDiscountRequest,
} from '../../../api/data-contracts'
import _ from 'lodash'
import {Field, FieldArray, FormikValues} from 'formik'
import {useSelector, useDispatch} from 'react-redux'
import {Api_Admin_GetDiscountPage, Api_Admin_UpsertDiscount} from '../../../apiClient/apiClient'
import FormMessage from '../../../components/common/messages/FormMessage'
import FormikForm from '../../../components/form/FormikForm'
import FormInput from '../../../components/form/FormInput'
import {getAdminState, setDiscountsPageData} from '../../../redux/adminSlice'
import {getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {FormStatus} from '../../../types/FormStatus'
import * as Yup from 'yup'
import CustomDropdown, {DropdownOption} from '../../../components/form/dropdown/CustomDropdown'
import {defterdar_log} from '../../../utils/utilities'
import moment from 'moment'
import FormCheckboxInput from '../../../components/form/FormCheckboxInput'

function AdminDiscountDetailPopup(props: {discountId?: number | null}) {
  const adminState = useSelector(getAdminState)

  const newDiscount: DiscountDTO = {
    isActive: true,
    prices: [
      {
        totalPrice: 0,
        totalPriceWithoutTax: 0,
        serviceProviderShares: [
          {
            serviceProviderId: 6,
            serviceProvider: 'Defterdar',
            totalPrice: 0,
            totalPriceWithoutTax: 0,
          },
          {
            serviceProviderRoleId: 2,
            serviceProviderRole: 'Mali Müşavir',
            totalPrice: 0,
            totalPriceWithoutTax: 0,
          },
        ],
      },
    ],
  }

  let discount =
    adminState.discountsPage?.discounts?.find((m) => m.id === props.discountId) ?? newDiscount

  const [modalShow, setModalShow] = useState(false)

  const [selectedServiceProviderId, setSelectedServiceProviderId] = useState(0)

  const [selectedPriceMode, setSelectedPriceMode] = useState(false)

  const handleModalClose = () => setModalShow(false)

  const load = () => {
    setModalShow(true)
  }

  const schema = Yup.object({})

  const dispatch = useDispatch()

  useEffect(() => {
    setSelectedPriceMode((discount?.discountPercentage ?? 0) > 0 ? false : true)
  }, [])

  const initData: UpsertDiscountRequest = {
    activitySchedule: discount?.activitySchedule ?? '',
    code: discount?.code ?? '',
    discountDescription: discount?.discountDescription ?? '',
    discountPercentage: discount?.discountPercentage ?? 0,
    eligibleProductIds: discount?.eligibleProductIds ?? [],
    endTime: moment(discount?.endTime ?? '').format('yyyy-MM-DD'),
    id: discount?.id ?? 0,
    isAutomatic: discount?.isAutomatic ?? false,
    prices: discount?.prices ?? [],
    startTime: moment(discount?.startTime ?? '').format('yyyy-MM-DD'),
  }

  function reloadDiscounts() {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetDiscountPage,
      })
    )
      .unwrap()
      .then(async function (response: AdminDiscountPageDataApiData) {
        dispatch<any>(setDiscountsPageData(response.data))
        setModalShow(false)
      })
  }
  const submit = async (values: UpsertDiscountRequest, actions: FormikValues) => {
    actions.setStatus()
    values.id = discount?.id
    values.startTime = !moment(values.startTime).isValid() ? null : values.startTime
    values.endTime = !moment(values.endTime).isValid() ? null : values.endTime
    if (selectedPriceMode) {
      values.discountPercentage = 0
    }

    defterdar_log(values)
    await dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_UpsertDiscount,
        body: values,
      })
    )
      .unwrap()
      .then(async (response: BooleanApiData) => {
        if (response.data == true) {
          actions.setStatus(new FormStatus(true, 'İndirim Kaydedildi.'))
          reloadDiscounts()
        } else {
          actions.setStatus(new FormStatus(false, response.serverMessage))
        }
      })
  }

  function generatePriceForServiceProvider(serviceProviderId: number): AdminProductPrice {
    const financialAdvisorUserId =
      adminState.productsPage?.serviceProviders?.find((m) => m.id === serviceProviderId)?.userId ??
      0

    const name = adminState.productsPage?.serviceProviders?.find(
      (m) => m.id === serviceProviderId
    )?.description

    return {
      financialAdvisorUserId: financialAdvisorUserId,
      financialAdvisorUser: name,
      serviceProviderShares: [
        {
          serviceProviderId: 6,
          serviceProvider: 'Defterdar',
          serviceProviderRole: '',
          serviceProviderRoleId: 0,
          totalPrice: 0,
          totalPriceWithoutTax: 0,
        },
        {
          serviceProviderId: serviceProviderId,
          serviceProvider: name,
          serviceProviderRole: '',
          serviceProviderRoleId: 0,
          totalPrice: 0,
          totalPriceWithoutTax: 0,
        },
      ],
    }
  }

  return (
    <>
      {!((discount?.id ?? 0) > 0) ? (
        <div
          className='btn btn-primary btn-sm mb-5'
          onClick={() => {
            load()
          }}
        >
          İndirim Ekle +
        </div>
      ) : (
        <div
          onClick={() => {
            load()
          }}
          className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
        >
          <KTSVG path='/media/icons/duotune/general/gen019.svg' className='svg-icon-3' />
        </div>
      )}

      <Modal size='xl' show={modalShow} onHide={handleModalClose} centered id='kt_modal_success'>
        <Modal.Header closeButton>
          {discount != null ? (
            <h5 className='modal-title'>
              {discount?.code} {'İndirim Kodu'}{' '}
            </h5>
          ) : (
            <h5 className='modal-title'>{'Yeni İndirim'} </h5>
          )}
        </Modal.Header>
        <Modal.Body>
          <FormikForm initialValues={initData} onSubmit={submit} validationSchema={schema}>
            {(form_props) => {
              return (
                <>
                  {defterdar_log(form_props.values)}
                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'code',
                          placeholder: 'CODE',
                        }}
                        istouched={form_props.touched.code ?? false}
                        errorMessage={form_props.errors.code}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-5'>
                      <FormInput
                        fieldAttributes={{
                          type: 'text',
                          name: 'discountDescription',
                          placeholder: 'DESCRIPTION',
                        }}
                        istouched={form_props.touched.discountDescription ?? false}
                        errorMessage={form_props.errors.discountDescription}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                      <FormInput
                        fieldAttributes={{
                          type: 'date',
                          name: 'startTime',
                          placeholder: 'BASLANGIC',
                        }}
                        istouched={form_props.touched.startTime ?? false}
                        errorMessage={form_props.errors.startTime}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-5'>
                      <FormInput
                        fieldAttributes={{
                          type: 'date',
                          name: 'endTime',
                          placeholder: 'BITIS',
                        }}
                        istouched={form_props.touched.endTime ?? false}
                        errorMessage={form_props.errors.endTime}
                        placeHolderOnTop={true}
                        checkValid={false}
                      />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='d-flex flex-column mb-7 col-xl-5 offset-xl-1'>
                      <FormCheckboxInput
                        description='OTOMATIK'
                        name='isAutomatic'
                        setFieldValue={form_props.setFieldValue}
                      />
                      <span className='mt-2'>
                        Otomatik seçildiğinde, aşağıda seçilen ürünler sepete eklendiği anda indirim
                        kodu girilmesine gerek kalmadan otomatik indirim uygulanacaktır.
                      </span>
                    </div>

                    <div className='d-flex flex-column mb-7 col-xl-2'>
                      <label className='form-label  fs-6 fw-bold'>Kaç ay uygulanacak?</label>
                      <input
                        type='number'
                        max={12}
                        className='form-control form-control-solid'
                        value={(form_props.values.activitySchedule?.split('1').length ?? 1) - 1}
                        onChange={(e) => {
                          if (Number(e.target.value) > 0) {
                            const ones = '1'.repeat(Math.min(Number(e.target.value), 12))
                            const zeros = '0'.repeat(12 - Math.min(Number(e.target.value), 12))
                            form_props.setFieldValue('activitySchedule', ones + zeros)
                          } else {
                            form_props.setFieldValue('activitySchedule', undefined)
                          }
                        }}
                      />
                    </div>
                    <div className='d-flex flex-column mb-7 col-xl-3'>
                      <span className='mt-2'>
                        Eğer kaç ay uygulanacağı belirlenirse, girilen değer kadar indirim uygulanır
                        sonrasında indirim uygulanmaz. Sadece şirket kuruluş paketi gibi periyodik
                        ödeme alınan ürünlerde geçerli olur. Eğer belirtilmezse, tüm aylar için
                        geçerli olur.
                      </span>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='d-flex flex-column mb-7 fv-row col-xl-5 offset-xl-1'>
                      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        {'İndirim Kodunun Uygulanacağı Ürünler'}
                      </label>
                      <div className='scroll h-400px border border-dotted p-4'>
                        <FieldArray
                          name='eligibleProductIds'
                          render={(arrayHelpers) => (
                            <>
                              {adminState.discountsPage?.products?.map((product) => (
                                <div key={product?.id} className='d-flex align mb-2'>
                                  <Field
                                    className='form-check-input me-2'
                                    type='checkbox'
                                    name={'eligibleProductIds'}
                                    checked={form_props.values.eligibleProductIds?.includes(
                                      product?.id ?? 0
                                    )}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                      const isChecked =
                                        form_props.values.eligibleProductIds?.includes(
                                          product?.id ?? 0
                                        )
                                      if (isChecked) {
                                        form_props.setFieldValue(
                                          'eligibleProductIds',
                                          form_props.values.eligibleProductIds?.filter(
                                            (item) => item !== product?.id
                                          )
                                        )
                                      } else {
                                        form_props.setFieldValue('eligibleProductIds', [
                                          ...(form_props.values.eligibleProductIds ?? []),
                                          product?.id,
                                        ])
                                      }
                                    }}
                                  />
                                  <label>{product?.productTypeName + ' - ' + product?.title}</label>
                                </div>
                              ))}
                            </>
                          )}
                        />
                      </div>
                    </div>
                    <div className='d-flex flex-column mb-7 fv-row col-xl-5'>
                      <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                        {'İndirim Miktarı'}
                      </label>
                      <div className='form-check form-switch form-check-custom form-check-solid mb-5'>
                        <input
                          className='form-check-input'
                          type='checkbox'
                          checked={selectedPriceMode}
                          id='flexSwitchDefault'
                          onChange={() => {
                            setSelectedPriceMode(!selectedPriceMode)
                          }}
                        />
                        <label className='form-check-label'>
                          {selectedPriceMode ? 'İndirim Modu (Mutlak)' : 'İndirim Modu (Yüzdesel)'}
                        </label>
                      </div>
                      {selectedPriceMode ? (
                        <>
                          <FieldArray
                            name='prices'
                            render={(arrayHelpers) => (
                              <div className='d-flex flex-column justify-content-center'>
                                <div className='row'>
                                  {form_props.values?.prices?.map((price, index) => (
                                    <div className='col-xl-12 px-2 pb-5' key={index}>
                                      <div className='card card-dashed p-6'>
                                        <div className='row mb-2'>
                                          <div className='col-xl-6 fw-bold fs-6 text-left'></div>
                                          <div className='col-xl-3 fw-bold fs-6 text-left'></div>
                                          <div className='col-xl-3 fw-bold fs-6 text-end'>
                                            {(price.financialAdvisorUserId ?? 0) > 0 && (
                                              <div className='card-toolbar'>
                                                <button
                                                  type='button'
                                                  className='btn btn-sm btn-icon btn-color-primary btn-active-light-primary'
                                                  data-kt-menu-trigger='click'
                                                  data-kt-menu-placement='bottom-end'
                                                  data-kt-menu-flip='top-end'
                                                  onClick={() => arrayHelpers.remove(index)}
                                                >
                                                  <KTSVG
                                                    path='/media/icons/duotune/abstract/abs012.svg'
                                                    className='svg-icon-muted svg-icon-2hx'
                                                  />
                                                </button>
                                              </div>
                                            )}
                                          </div>
                                        </div>
                                        <div className='row mb-2'>
                                          <div className='col-xl-6 fw-bold fs-6 text-left'>
                                            {price.financialAdvisorUserId
                                              ? price.financialAdvisorUser + ' İçin Fiyatlar'
                                              : 'Varsayılan Fiyatlar'}
                                          </div>
                                          <div className='col-xl-3 fw-bold fs-6 text-left'>
                                            KDV'siz
                                          </div>
                                          <div className='col-xl-3 fw-bold fs-6 text-left'>
                                            KDV'li
                                          </div>
                                        </div>
                                        {price?.serviceProviderShares?.map((m, i) => {
                                          return (
                                            <div className='row mb-2' key={i}>
                                              <div className='col-xl-6 fs-6 text-left pb-3'>
                                                <div>
                                                  {(m.serviceProviderRoleId ?? 0) > 0 ? (
                                                    <div>{m.serviceProviderRole}</div>
                                                  ) : (
                                                    <>{m.serviceProvider} </>
                                                  )}
                                                </div>
                                              </div>
                                              <div className='col-xl-3'>
                                                <FormInput
                                                  fieldAttributes={{
                                                    type: 'number',
                                                    name: `prices.${index}.serviceProviderShares.${i}.totalPriceWithoutTax`,
                                                    placeholder: '',
                                                    onChange: (event: any) => {
                                                      const value = parseFloat(
                                                        event.target.value == ''
                                                          ? 0
                                                          : event.target.value
                                                      )
                                                      form_props.setFieldValue(
                                                        `prices.${index}.serviceProviderShares.${i}.totalPriceWithoutTax`,
                                                        value
                                                      )

                                                      form_props.setFieldValue(
                                                        `prices.${index}.serviceProviderShares.${i}.totalPrice`,
                                                        value * 1.2
                                                      )
                                                    },
                                                  }}
                                                  istouched={true}
                                                  errorMessage={undefined}
                                                  placeHolderOnTop={false}
                                                  checkValid={false}
                                                />
                                              </div>
                                              <div className='col-xl-3'>
                                                <FormInput
                                                  fieldAttributes={{
                                                    type: 'number',
                                                    name: `prices.${index}.serviceProviderShares.${i}.totalPrice`,
                                                    placeholder: '',
                                                    onChange: (event: any) => {
                                                      const value = parseFloat(
                                                        event.target.value == ''
                                                          ? 0
                                                          : event.target.value
                                                      )
                                                      form_props.setFieldValue(
                                                        `prices.${index}.serviceProviderShares.${i}.totalPrice`,
                                                        value
                                                      )

                                                      // form_props.setFieldValue(
                                                      //   `prices.${index}.shares.${i}.totalPriceWithoutTax`,
                                                      //   value / 1.2
                                                      // )
                                                    },
                                                  }}
                                                  istouched={true}
                                                  errorMessage={undefined}
                                                  placeHolderOnTop={false}
                                                  checkValid={false}
                                                />
                                              </div>
                                            </div>
                                          )
                                        })}

                                        <div className='row'>
                                          <div className='col-xl-6 fs-6 text-left'>
                                            {!price.financialAdvisorUserId ? (
                                              <div
                                                className='btn btn-primary btn-sm fs-8'
                                                onClick={() => {
                                                  const defaultShares =
                                                    form_props.values?.prices?.find(
                                                      (m) => (m.financialAdvisorUserId ?? 0) === 0
                                                    )?.serviceProviderShares

                                                  form_props.values.prices?.forEach((m, i) => {
                                                    if ((m.financialAdvisorUserId ?? 0) > 0) {
                                                      price.serviceProviderShares?.forEach(
                                                        (s, j) => {
                                                          defterdar_log(
                                                            defaultShares?.at(j)
                                                              ?.totalPriceWithoutTax
                                                          )

                                                          form_props.setFieldValue(
                                                            `prices.${i}.serviceProviderShares.${j}.totalPriceWithoutTax`,
                                                            defaultShares?.at(j)
                                                              ?.totalPriceWithoutTax
                                                          )
                                                          form_props.setFieldValue(
                                                            `prices.${i}.serviceProviderShares.${j}.totalPrice`,
                                                            defaultShares?.at(j)?.totalPrice
                                                          )
                                                        }
                                                      )
                                                    }
                                                  })
                                                }}
                                              >
                                                Tüm Mali Müşavirlere Aktar
                                              </div>
                                            ) : (
                                              <div>Toplam</div>
                                            )}
                                          </div>
                                          <div className='col-xl-3 fs-6 text-left py-3'>
                                            <div>
                                              {_.sumBy(
                                                form_props.values.prices?.at(index)
                                                  ?.serviceProviderShares,
                                                (s) => s.totalPriceWithoutTax ?? 0
                                              ).toFixed(2)}
                                            </div>
                                          </div>
                                          <div className='col-xl-3 fs-6 text-left py-3'>
                                            {_.sumBy(
                                              form_props.values.prices?.at(index)
                                                ?.serviceProviderShares,
                                              (s) => s.totalPrice ?? 0
                                            ).toFixed(2)}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))}
                                </div>

                                <div className='row'>
                                  <div className='col-xl-12'>
                                    <div className='row'>
                                      <div className='col-xl-6'>
                                        <CustomDropdown
                                          fieldAttributes={{
                                            name: `temp_service_provider_id`,
                                            placeholder: 'SERVIS_SAGLAYICI_SECINIZ',
                                            onChange: (event: any) => {
                                              setSelectedServiceProviderId(
                                                parseInt(event.target.value)
                                              )
                                            },
                                          }}
                                          noSelectionText='SERVIS_SAGLAYICI_SECINIZ'
                                          options={
                                            adminState.productsPage?.serviceProviders?.map((m) => {
                                              const v: DropdownOption = {
                                                label: m.description ?? '',
                                                value: m.id ?? 0,
                                              }
                                              return v
                                            }) ?? []
                                          }
                                          noSelectionValue={0}
                                          placeHolderOnTop={false}
                                          setFieldValue={form_props.setFieldValue}
                                          valueConverter={parseInt}
                                        />
                                      </div>
                                      <div className='col-xl-6'>
                                        <div
                                          onClick={() => {
                                            const newPrice =
                                              generatePriceForServiceProvider(
                                                selectedServiceProviderId
                                              )
                                            defterdar_log(newPrice)
                                            arrayHelpers.push(newPrice)
                                          }}
                                          className='btn btn-lg btn-primary me-3'
                                        >
                                          Özel Fiyat Ekle +
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          />
                        </>
                      ) : (
                        <>
                          <div className='row'>
                            <div className='col-xl-6'>
                              <FormInput
                                fieldAttributes={{
                                  type: 'number',
                                  name: 'discountPercentage',
                                  placeholder: 'YUZDESEL_INDIRIM_ORANI',
                                }}
                                istouched={form_props.touched.discountPercentage ?? false}
                                errorMessage={form_props.errors.discountPercentage}
                                placeHolderOnTop={true}
                                checkValid={false}
                              />
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className='row mt-5'>
                    <div className='col-xl-4 offset-xl-4 text-center'>
                      <FormMessage {...form_props.status} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='text-center'>
                      <button type='submit' className='btn btn-lg btn-primary me-3 mt-5'>
                        Kaydet
                      </button>
                    </div>
                  </div>
                </>
              )
            }}
          </FormikForm>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default AdminDiscountDetailPopup
