import {useDispatch, useSelector} from 'react-redux'
import {
  AdminDiscountPageData,
  AdminDiscountPageDataApiData,
  AdminProductDTO,
  AdminProductPageDataApiData,
  DiscountDTO,
} from '../../../api/data-contracts'
import {getAdminState, setDiscountsPageData, setProductsPageData} from '../../../redux/adminSlice'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import paginationFactory, {
  PaginationListStandalone,
  PaginationProvider,
  PaginationTotalStandalone,
  SizePerPageDropdownStandalone,
} from 'react-bootstrap-table2-paginator'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css'
import filterFactory, {selectFilter, textFilter} from 'react-bootstrap-table2-filter'

import {Api_Admin_GetDiscountPage, Api_Admin_GetProductsPage} from '../../../apiClient/apiClient'
import {getRequestAsync, postRequestAsync} from '../../../redux/commonSlice'
import {useEffect} from 'react'
import {getCurrencySymbol} from '../../../utils/utilities'
import clsx from 'clsx'
import AdminProductDetailPopup from '../Products/AdminProductDetailPopup'
import moment from 'moment'
import AdminDiscountDetailPopup from './AdminDiscountDetailPopup'

function AdminDiscountPage() {
  const dispatch = useDispatch()
  const adminState = useSelector(getAdminState)

  useEffect(() => {
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetDiscountPage,
      })
    )
      .unwrap()
      .then(async function (response: AdminDiscountPageDataApiData) {
        dispatch<any>(setDiscountsPageData(response.data))
      })
  }, [])

  const columns: ColumnDescription[] = [
    {
      dataField: 'isActive',
      text: 'Aktif',
      sort: true,
      headerClasses: 'text-center',
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: DiscountDTO, rowIndex: number, colIndex: number) => (
        <div className='text-center'>
          {m.isActive && <i className='bi bi-check fs-2x text-success text-center'></i>}
        </div>
      ),
    },
    {
      dataField: 'code',
      text: 'İndirim Kodu',
      sort: true,
      headerStyle: {width: '40%'},
      formatter: (cell: any, m: DiscountDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6'>{m.code}</div>
        </>
      ),
    },
    {
      dataField: 'discountPercentage',
      text: 'İndirim Miktarı',
      sort: true,
      headerStyle: {width: '10%'},
      formatter: (cell: any, m: DiscountDTO, rowIndex: number, colIndex: number) => (
        <>
          {m.discountPercentage ? (
            <div className='text-dark text-center fw-bold d-block fs-6'>
              % {m.discountPercentage}
            </div>
          ) : (
            <>
              <div className='text-dark text-center fw-bold d-block fs-6'>
                {m.prices?.find((f) => (f.financialAdvisorUserId ?? 0) === 0)?.totalPrice} {'₺'}
              </div>
            </>
          )}
        </>
      ),
    },
    {
      dataField: 'startTime',
      text: 'Başlangıç',
      sort: true,
      headerStyle: {width: '20%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: DiscountDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark text-center fw-bold d-block fs-6'>
            {m.startTime && moment(m.startTime).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'endTime',
      text: 'Bitiş',
      sort: true,
      headerStyle: {width: '20%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: DiscountDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark text-center fw-bold d-block fs-6'>
            {m.endTime && moment(m.endTime).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: '',
      text: 'Detay',
      sort: true,
      headerStyle: {width: '5%'},
      formatter: (cell: any, m: DiscountDTO, rowIndex: number, colIndex: number) => (
        <>
          <div className='d-flex justify-content-center flex-shrink-0'>
            <AdminDiscountDetailPopup discountId={m.id} />
          </div>
        </>
      ),
    },
  ]

  const rowClasses = (row: AdminProductDTO, rowIndex: number): string => {
    return clsx({
      'bg-light-danger': !row.isActive,
    })
  }

  const newDiscount: DiscountDTO = {
    isActive: true,
    prices: [
      {
        totalPrice: 0,
        totalPriceWithoutTax: 0,
        serviceProviderShares: [
          {
            serviceProviderId: 6,
            serviceProvider: 'Defterdar',
            totalPrice: 0,
            totalPriceWithoutTax: 0,
          },
          {
            serviceProviderRoleId: 2,
            serviceProviderRole: 'Mali Müşavir',
            totalPrice: 0,
            totalPriceWithoutTax: 0,
          },
        ],
      },
    ],
  }

  return (
    <>
      <div className={`card card-stretch`}>
        <div className='card-body py-3'>
          <AdminDiscountDetailPopup discountId={0} />

          <div className='table-responsive'>
            {(adminState.discountsPage?.discounts?.length ?? 0) > 0 && (
              <>
                <PaginationProvider
                  pagination={paginationFactory({
                    custom: true,
                    totalSize: adminState?.discountsPage?.discounts?.length,
                    sizePerPage: 200,
                    paginationSize: 200,
                  })}
                >
                  {({paginationProps, paginationTableProps}) => (
                    <>
                      <div>
                        <SizePerPageDropdownStandalone {...paginationProps} />
                        <PaginationTotalStandalone {...paginationProps} />
                        <PaginationListStandalone {...paginationProps} />
                        <BootstrapTable
                          {...paginationTableProps}
                          rowClasses={rowClasses}
                          keyField='id'
                          filter={filterFactory()}
                          filterPosition='top'
                          data={
                            _.orderBy(adminState.discountsPage?.discounts, (o) => o.id, 'desc') ??
                            []
                          }
                          columns={columns}
                          classes='table table-hover table-row-dashed table-rounded mt-5'
                        />
                      </div>
                    </>
                  )}
                </PaginationProvider>
              </>
            )}
          </div>

          {/* begin::Table container */}

          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export default AdminDiscountPage
