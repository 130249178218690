import React, {useEffect, useState} from 'react'
import {
  IdDescriptionDTO,
  IdDescriptionDTOListApiData,
  PaymentReport,
  PaymentReportListApiData,
  PaymentReportRequest,
} from '../../../../api/data-contracts'
import {
  Api_Admin_GetPaymentReport,
  Api_Admin_GetPaymentStates,
  Api_Admin_GetReferences,
} from '../../../../apiClient/apiClient'
import {getAdminState, setReport} from '../../../../redux/adminSlice'
import {getRequestAsync, postRequestAsync, setIsLoading} from '../../../../redux/commonSlice'
import {useDispatch, useSelector} from 'react-redux'
import _ from 'lodash'
import BootstrapTable, {ColumnDescription} from 'react-bootstrap-table-next'
import moment from 'moment'
import ExportCSV from '../../../../components/admin/common/ExportCSV'
import {ReportEnum} from '../../../../types/ReportEnum'
import FormikForm from '../../../../components/form/FormikForm'
import DateRangeSelectorHorizontal from '../../../../components/form/dropdown/DateRangeSelectorHorizontal'
import {defterdar_log,  getPaymentStateColor} from '../../../../utils/utilities'
import Select from 'react-select'
import clsx from 'clsx'
import paginationFactory, {
  PaginationProvider,
  SizePerPageDropdownStandalone,
  PaginationTotalStandalone,
  PaginationListStandalone,
} from 'react-bootstrap-table2-paginator'
import { DropdownOption } from '../../../../components/form/dropdown/CustomDropdown'

function PaymentReportComponent() {
  const adminState = useSelector(getAdminState)
  const dispatch = useDispatch()
  const columns: ColumnDescription[] = [
    {
      dataField: 'companyName',
      text: 'Firma',
      sort: true,
      headerStyle: {width: '15%'},
      headerClasses: 'text-start',
      formatter: (cell: any, m: PaymentReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-start'>{m.companyName}</div>
          <div className='text-muted d-block fs-8 text-start'>{m.userName}</div>
        </>
      ),
    },
    {
      dataField: 'dueTime',
      text: 'Vade Zamanı',
      sort: true,
      headerStyle: {width: '5%'},
      headerClasses: 'text-start',
      formatter: (cell: any, m: PaymentReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-start'>
            {moment(m.dueTime).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'paymentTime',
      text: 'Ödeme Zamanı',
      sort: true,
      headerStyle: {width: '5%'},
      headerClasses: 'text-start',
      formatter: (cell: any, m: PaymentReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-start'>
            {m.paymentTime && moment(m.paymentTime).format('DD.MM.yyyy HH:mm')}
          </div>
        </>
      ),
    },
    {
      dataField: 'priceWithoutTax',
      text: 'KDVsiz Miktar',
      sort: true,
      headerStyle: {width: '5%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: PaymentReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>
            {m.priceWithoutTax?.toFixed(0)} ₺
          </div>
        </>
      ),
    },
    {
      dataField: 'price',
      text: 'Miktar',
      sort: true,
      headerStyle: {width: '5%'},
      headerClasses: 'text-end',
      formatter: (cell: any, m: PaymentReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-dark fw-bold d-block fs-6 text-end'>{m.price?.toFixed(0)} ₺</div>
        </>
      ),
    },
    {
      dataField: 'stateDescription',
      text: 'Durum',
      sort: true,
      headerStyle: {width: '10%'},
      headerClasses: 'text-center',
      formatter: (cell: any, m: PaymentReport, rowIndex: number, colIndex: number) => (
        <>
          <div className='text-center'>
            <span className={clsx('badge', getPaymentStateColor(m.stateId ?? 0))}>
              {m.stateDescription}
            </span>
          </div>
        </>
      ),
    },
  ]

  function getExcelData() {
    return (_.orderBy(adminState?.reports.paymentReport, ['paymentTime'], ['desc']) ?? []).map(
      (m) => {
        return {
          Firma: m.companyName,
          Kişi: m.userName,
          'Vade Zamanı': m.dueTime,
          'Ödeme Zamanı': m.paymentTime,
          'KDVsiz Miktar': m.priceWithoutTax,
          Miktar: m.price,
          Durum: m.stateDescription,
        }
      }
    )
  }

  const [paymentStates, setPaymentStates] = useState<IdDescriptionDTO[]>([])

  const loadReport = (request: PaymentReportRequest) => {
    dispatch(setIsLoading(true))
    dispatch<any>(
      postRequestAsync({
        postRequestFunction: Api_Admin_GetPaymentReport,
        body: request,
      })
    )
      .unwrap()
      .then(async function (response: PaymentReportListApiData) {
        dispatch(
          setReport({
            reportEnum: ReportEnum.PaymentReport,
            paymentReport: response.data,
          })
        )
      })
  }

  useEffect(() => {
    dispatch(setIsLoading(true))
    dispatch<any>(
      getRequestAsync({
        getRequestFunction: Api_Admin_GetPaymentStates,
      })
    )
      .unwrap()
      .then(async function (response: IdDescriptionDTOListApiData) {
        setPaymentStates(response.data ?? [])
        loadReport(initFilterValues)
      })
  }, [])

  const paymentStateOptions: DropdownOption[] = [
    {
      label: 'Hepsi',
      value: '0',
    },
    {
      label: 'Başarılı',
      value: '1',
    },
    {
      label: 'Başarısız',
      value: '2',
    },
    {
      label: 'Bekleyen',
      value: '3',
    },
  ]

  const initFilterValues: PaymentReportRequest = {
    finishTime: moment(new Date()).startOf('month').add(1, 'month').format('yyyy-MM-DD'),
    startTime: moment(new Date()).startOf('month').format('yyyy-MM-DD'),
    stateId: 0,
    simpleStateId: 0,
  }

  return (
    <div>
      <div className='row mb-7'>
        <FormikForm initialValues={initFilterValues} onSubmit={loadReport}>
          {(props) => {
            return (
              <>
                <div className='row'>
                  <div className='d-flex flex-column mb-3 fv-row col-xl-5'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Tarihi Aralığı</span>
                    </label>
                    <DateRangeSelectorHorizontal
                      setFieldValue={props.setFieldValue}
                      finishKey='finishTime'
                      startKey='startTime'
                    />
                  </div>

                  <div className='d-flex flex-column mb-3 fv-row col-xl-3'>
                    <label className='d-flex align-items-center fs-6 fw-bold form-label mb-2'>
                      <span className=''>Ödeme Durumu</span>
                    </label>
                    <Select
                      options={paymentStateOptions}
                      closeMenuOnSelect={true}
                      name='simpleStateId'
                      onChange={(value) => {
                        props.setFieldValue(
                          'simpleStateId',
                          parseInt((value as DropdownOption).value)
                        )
                      }}
                      classNames={{
                        control: (state) => 'form-select form-select-solid fs-6',
                      }}
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          paddingTop: '0',
                          paddingBottom: '0',
                        }),
                        dropdownIndicator: (baseStyles, state) => ({
                          ...baseStyles,
                          display: 'none',
                        }),
                      }}
                    />
                  </div>

                  <div className='d-flex mb-3 fv-row  col-xl-2 align-items-end'>
                    <a
                      className='btn btn-lg btn-primary me-3 col-12'
                      onClick={() => {
                        props.resetForm({values: initFilterValues})
                      }}
                    >
                      <span className='indicator-label'>Temizle</span>
                    </a>
                  </div>

                  <div className='d-flex mb-3 fv-row col-xl-2 align-items-end'>
                    <button type='submit' className='btn btn-lg btn-primary me-3 col-12'>
                      <span className='indicator-label'>Filtrele</span>
                    </button>
                  </div>
                </div>
              </>
            )
          }}
        </FormikForm>
      </div>

      <div className='table-responsive'>
        <div>
          {(adminState?.reports.paymentReport?.length ?? 0) > 0 && (
            <PaginationProvider
              pagination={paginationFactory({
                custom: true,
                totalSize: adminState?.reports.paymentReport?.length,
                sizePerPage: 50,
                paginationSize: 50,
                paginationTotalRenderer: (from, to, size) => (
                  <span className='react-bootstrap-table-pagination-total ms-3'>
                    Toplam {size} ödemeden {from} - {to} arası gösteriliyor{' '}
                    <ExportCSV csvData={getExcelData()} fileName={'Ödeme Raporu'} />
                  </span>
                ),
              })}
            >
              {({paginationProps, paginationTableProps}) => (
                <>
                  <SizePerPageDropdownStandalone {...paginationProps} className='mx-5' />
                  <PaginationTotalStandalone {...paginationProps} />
                  <PaginationListStandalone {...paginationProps} />

                  <BootstrapTable
                    {...paginationTableProps}
                    classes='table table-hover table-responsive table-row-bordered border-dark table-rounded gy-7 gx-5'
                    headerClasses='table-header fw-bolder fs-5'
                    keyField='paymentId'
                    data={
                      _.orderBy(adminState?.reports.paymentReport, ['PaymentTime'], ['desc']) ?? []
                    }
                    columns={columns}
                  />
                </>
              )}
            </PaginationProvider>
          )}
        </div>
      </div>
    </div>
  )
}

export default PaymentReportComponent
